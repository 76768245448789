import { Payload as LegendPayload } from 'recharts/types/component/DefaultLegendContent';

import { FormatUtcDate, ParseGqlUtcDate } from '@/helpers/dates';
import { CalcPercentDiff, FormatPercentage } from '@/helpers/numbers';
import { ProcessedIndexItem } from '@/types/index';

export const FormatPercent = (tickItem: number) => {
    return FormatPercentage(tickItem);
};

export const FormatDateMonthYear = (tickItem: string) => {
    const date = ParseGqlUtcDate(tickItem);
    const formatedDate = FormatUtcDate(date, 'MMM yyyy') as string;
    return formatedDate.toUpperCase();
};

export const FormatDateMonthDayYear = (tickItem: string) => {
    const date = ParseGqlUtcDate(tickItem);
    return FormatUtcDate(date, 'MMM d, yyyy') as string;
};

export const ConvertIndexDataToPercent = (benchmarkArray: Array<ProcessedIndexItem>): Array<ProcessedIndexItem> => {
    const symbolsToFirstValues: { [key: string]: number } = {};
    const convertedBenchmarkArray: Array<ProcessedIndexItem> = benchmarkArray.map((item) => {
        const tempObj: ProcessedIndexItem = {
            asOfDate: item.asOfDate,
            isBackcalculated: item.isBackcalculated,
            symbolValues: {},
        };

        for (const property in item.symbolValues) {
            if (!(property in symbolsToFirstValues)) {
                symbolsToFirstValues[property] = item.symbolValues[property];
            }

            tempObj.symbolValues[property] = CalcPercentDiff(
                symbolsToFirstValues[property],
                item.symbolValues[property]
            );
        }
        return tempObj;
    });

    return convertedBenchmarkArray;
};

export const PrepareBacktestData = (
    percentData: Array<ProcessedIndexItem>,
    symbols: Array<string>,
    indexSymbol: string,
    backtestSymbol: string
): {
    updatedPercentData: Array<ProcessedIndexItem>;
    updatedSymbols: Array<string>;
} => {
    // set backfill data so a new line forms
    let lastGrayedPointIndex = -1;
    const symbolsCopy = [...symbols];

    percentData.forEach((value, index) => {
        if (value.isBackcalculated) {
            value.symbolValues[backtestSymbol] = value.symbolValues[indexSymbol];
            delete value.symbolValues[indexSymbol];
            lastGrayedPointIndex = index;
        }
    });

    // If there is no backfill data...
    if (lastGrayedPointIndex !== -1) {
        // add the backtest symbol
        symbolsCopy.splice(1, 0, backtestSymbol);

        // add the first non-index date to eliminate the gap between backfill snapshots and live snapshots.
        // skip if there is *only* backfill data
        if (lastGrayedPointIndex < percentData.length - 1) {
            const val = percentData[lastGrayedPointIndex + 1];
            val.symbolValues[backtestSymbol] = val.symbolValues[indexSymbol];
        }
    }

    return {
        updatedPercentData: percentData,
        updatedSymbols: symbolsCopy,
    };
};

export const GetLegendData = (
    symbols: Array<string>,
    indexSymbol: string,
    colorMap: Map<string, string>,
    { primaryOnly = false }: { primaryOnly?: boolean } = {}
): Array<LegendPayload> => {
    const candidates = primaryOnly ? [indexSymbol] : symbols;

    return candidates.map((symbol) => {
        return {
            color: colorMap.get(symbol),
            id: symbol,
            value: symbol || indexSymbol,
        };
    });
};
