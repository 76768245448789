import { ParseGqlUtcDate } from '@/helpers/dates';
import { ProcessedIndexItem } from '@/types/index';

export const FilterIndexDataOlderThanDate = (
    sortedData: Array<ProcessedIndexItem>,
    filter: Date | null
): Array<ProcessedIndexItem> => {
    return FilterIndexDataDateRange(sortedData, filter, null);
};

export const FilterIndexDataDateRange = (
    sortedData: Array<ProcessedIndexItem>,
    min: Date | null,
    max: Date | null
): Array<ProcessedIndexItem> => {
    return sortedData
        .filter(({ asOfDate }) => !min || ParseGqlUtcDate(asOfDate) >= min)
        .filter(({ asOfDate }) => !max || ParseGqlUtcDate(asOfDate) <= max);
};
