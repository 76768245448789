import { DeepDiveUrlParams, getCompanyPageUrls, GetCompanyPageUrlsType, getDeepDiveUrl } from '@/helpers/company';
import { SITE_BASE_URL } from '@/lib/constants';

type PathOptions = {
    includeBaseUrl?: boolean;
};

export const GetOrganizationPublicRedirectPath = (organizationId: string, pathOptions: PathOptions = {}): string => {
    return applyPathOptions(`/team/${encodeURIComponent(organizationId.toLowerCase())}`, pathOptions);
};

export const GetIndexSymbolPublicPath = (symbol: string, pathOptions: PathOptions = {}): string => {
    return applyPathOptions(`/index/${encodeURIComponent(symbol.toLowerCase())}`, pathOptions);
};

export const GetIndexSymbolPercentGraphEmbedPath = (symbol: string, pathOptions: PathOptions = {}): string => {
    return applyPathOptions(`/embed/index/percent-graph/${encodeURIComponent(symbol.toLowerCase())}`, pathOptions);
};

export const GetAnalystPublicPath = (requestId: string, pathOptions: PathOptions = {}): string => {
    return applyPathOptions(`/analyst?l=${encodeURIComponent(requestId)}`, pathOptions);
};
export const GetDeepDivePublicPath = ({
    instrumentId,
    requestId,
    resultTheme,
    themeName,
    useNewUrl = false,
    pathOptions = {},
}: DeepDiveUrlParams & { pathOptions?: PathOptions }) => {
    const url = getDeepDiveUrl({ instrumentId, requestId, resultTheme, themeName, useNewUrl });

    return applyPathOptions(url, pathOptions);
};
export const GetCompanyPagePublicPath = ({
    ticker,
    exchange,
    analystRequestId,
    expandedModulePagePath,
    requestId,
    resultTheme,
    themeName,
    pathOptions = {},
}: GetCompanyPageUrlsType & { pathOptions?: PathOptions }) => {
    const url = getCompanyPageUrls({
        analystRequestId,
        exchange,
        expandedModulePagePath,
        requestId,
        resultTheme,
        themeName,
        ticker,
    });

    return applyPathOptions(url, pathOptions);
};

const applyPathOptions = (path: string, { includeBaseUrl = false }: PathOptions = {}) => {
    const prefix = includeBaseUrl ? SITE_BASE_URL : '';
    return `${prefix}${path}`;
};

export const navigateWithoutRerender = ({ pathname }: { pathname: string }) => {
    if (typeof window !== 'undefined') {
        // window.history.replaceState({}, '', pathname);
        window.history.replaceState({ ...window.history.state, as: pathname, url: pathname }, '', pathname);
    }
};
